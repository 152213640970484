import React, { useCallback, useEffect, useState } from "react"
import { useParams } from "@reach/router"
import { Helmet } from "react-helmet"

import { Amplify, API, graphqlOperation } from "aws-amplify"
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api"
import { getTagBlogs } from "../api/queries"
import awsconfig from "../aws-exports"
import { BlogForList } from "../utils/types"
import Layout from "../components/layout"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { Chip } from "@material-ui/core"
import { graphql, navigate, useStaticQuery } from "gatsby"
import BlogList from "../components/blog-list"
import BlogLoadMore from "../components/blog-load-more"
import Loading from "../components/loading"

Amplify.configure(awsconfig)

const getBlogs = async (tag: string, nextToken: string | null = null) => {
  const params = {
    tag: tag,
    limit: 10,
    nextToken,
  }

  const {
    data: {
      getTag: {
        blogs: { items: result, nextToken: token },
      },
    },
  } = (await API.graphql({
    ...graphqlOperation(getTagBlogs, params),
    authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
  })) as any

  return {
    items: result,
    nextToken: token,
  }
}

export default function TagBlogs() {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const { tag } = useParams()

  const [blogs, setBlogs] = useState<BlogForList[]>([])
  const [nextToken, setNextToken] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const loadBlogs = async () => {
      setLoading(true)
      try {
        const { items: result, nextToken: token } = await getBlogs(tag)

        const items: BlogForList[] = []
        for (const item of result) {
          items.push({
            ...item.blog,
          })
        }
        setBlogs(items)
        setNextToken(token)
      } catch (err) {
        console.log("[DEBUG] err: ", err)
        alert("Error!")
      }
      setLoading(false)
    }

    setBlogs([])
    loadBlogs()
  }, [tag])

  const clickLoadMore = useCallback(async () => {
    setLoading(true)
    const { items: result, nextToken: token } = await getBlogs(tag, nextToken)
    setBlogs(prev => {
      const items: BlogForList[] = []
      for (const item of result) {
        items.push({
          ...item.blog,
        })
      }

      return [...prev, ...items]
    })
    setNextToken(token)
    setLoading(false)
  }, [tag, nextToken])

  return (
    <React.Fragment>
      <Loading loading={loading && blogs.length === 0} />
      <Helmet>
        <title>
          Tagged with {tag} | {data.site.siteMetadata.title}
        </title>
      </Helmet>
      <Grid container>
        <Typography variant="h5" gutterBottom={true}>
          Tagged:&nbsp;
          <Chip
            label={tag}
            color="secondary"
            onDelete={() => {
              navigate("/")
            }}
          />
        </Typography>
        <BlogList blogs={blogs} />
        <BlogLoadMore
          show={!!nextToken}
          loading={loading}
          clickHandler={clickLoadMore}
        />
      </Grid>
    </React.Fragment>
  )
}
