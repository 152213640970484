import React from "react"
import { Router } from "@reach/router"
import TagBlogs from "../views/tag-blogs"

export default function Tag() {
  return (
    <Router basepath="/tag">
      <TagBlogs path="/:tag" />
    </Router>
  )
}
